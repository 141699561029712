import { HeroCTA } from '../../Hero'
import Section from '../../Section/Section'

export default function Hero({
    title,
    btnTitle,
    to,
    description,
    heroImage,
    helperText,
}) {
    return (
        <Section pt={0} pb={0} backgroundColor="#e3eff6">
            <HeroCTA
                title={title}
                btnTitle={btnTitle}
                vertical={false}
                description={description}
                heroImage={heroImage}
                jumpTo={to}
                helperText={helperText}
            />
        </Section>
    )
}
